@import 'node_modules/primeflex/primeflex.scss';
@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';
$PrimaryColor: #49464d;
$brandWhite: #ffffff;
$inputLabelColor: #000000;
$inputBorder: #dbe2ea;
$BtnCancelBgColor: #535965;
$secondaryColor:#f8a91b;
//Validation label colors
$validationLabelColor: red;

//Form Color
$formHeaderBorder: #edf0f5;

//font title
$titleTextFont: 'Poppins', sans-serif;

//Input colors
$inputFieldColor: #495057;
$inputbgColor: #f1f1f1;
$placeholderColor: #6c757d;
$inputLabelColor: #000000;
$grayColor: #525864;
$redButton: #ff7171;
$greenButton: #13a28a;
$highlightColor: #e2f4c5;

//Card Background Color
$CardBgColor: rgb(248, 248, 248);

body {
    font-family: 'Poppins', sans-serif;
}

.Checker__Data_Detail {
    .Checker__Data__Table {
        width: 100%;
        border: 1px Solid lightgrey;
        border-collapse: collapse;

        .Checker__PresentTable__Head {
            background: #14a38b;
            color: white;
            padding: 20px 0px;
        }

        .Checker__ModifiedTable__Head {
            background: #ff7171;
            color: white;
            padding: 20px 0px;
        }

        .Checker__Table__Data {
            padding: 10px 0px;
            width: 30%;
            text-align: center;
            border: 1px solid lightgrey;
        }

        .Checker__Applicant__Detail {
            padding: 20px 0px;
        }
    }
}

.p-datatable-thead > tr > .Table__ActionHeading__CentredAlign {
    div {
        justify-content: center;
    }
}

.Kyc__Picklist__Header {
    display: flex;
    justify-content: space-between;
    color: $PrimaryColor;

    h5 {
        margin: 0px !important;
    }
}

.Picklist__Item {
    display: flex;
    justify-content: space-between;
}

.Picklist__Checkbox {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 999999;
}

.Table__Textalign {
    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.p-datatable-thead > tr > .Table__ActionHeading__CentredAlign {
    div {
        justify-content: center;
    }
}

.Table__Checkboxalign {
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
}

.Full__Length {
    width: 100%;
    padding: 0px;
}

.Login__Input {
    padding: 14px;
}

.Login__Button {
    background-color: $secondaryColor !important;
    border: none;
    border-radius: 5px;
    padding: 15px;
    width: 100% !important;
    &:hover {
        background-color: $PrimaryColor !important;
    }
}

.Signin__Heading {
    font-size: 24px;
    font-weight: 600;
    color: $brandWhite;
    text-align: center;
}

// .checkbox_login {
//     border: 2px solid #ced4da;
//     background: $brandWhite;
//     width: 22px;
//     height: 22px;
//     color: #495057;
//     border-radius: 6px;
// }
.Remember_label {
    color: $brandWhite;
}

.forgot_label {
    color: $brandWhite;
    margin-top: 5px;
    margin-right: 12px;
}

.Login__Input {
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: $brandWhite;
    color: #000;
}

.Login__Input:focus {
    outline: none;
    border: none;
}

.p-error {
    color: #fc6161;
    float: left;
}

.p-input-icon-left > i,
.p-input-icon-right > i,
.p-input-icon-left > svg,
.p-input-icon-right > svg,
.p-input-icon-left > .p-input-prefix,
.p-input-icon-right > .p-input-suffix {
    position: absolute;
    top: 50%;
    margin-top: -1rem;
}

.Remember__Forget {
    width: 355px;
}

.ForgotPassword__Alignment {
    text-align: right;
}

//data table and buttons on module tab
.Module__Actions {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    margin: 0 auto;
}

//data table and buttons on menu tab
.Menu__Actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 100px;
    margin: 0 auto;
}

// Data table and buttons CSS
.actions {
    display: flex;
    // justify-content: space-evenly;
    justify-content: flex-start;
    align-items: center;

    &.actions > * {
        margin: 0 0.25rem;
    }
}
 .Btn__Add__ {
     padding: 0px 15px;

     margin-right: 5px;

     height: 40px;

     border-radius: 10px;

     background: $PrimaryColor;

     color: $secondaryColor;

     border: none;

     font-size: 13px;

     font-weight: 500;

     box-shadow: none !important;

     white-space: pre-wrap;

     min-width: 150px;
 }

.p-datatable-thead > tr > .Table__ActionHeading__CentredAlign {
    div {
        justify-content: center;
    }
}

.Top__Btn__Border {
    border: 1px solid lightgrey;
}
.Button__Card{
    border: 1px solid lightgrey;
    background: $brandWhite;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Top__Btn__Commission {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background: $brandWhite;
    padding: 10px;
    border-radius: 10px;

    > div:nth-child(1) {
        // width: 50%;

        display: flex;

        align-items: center;

        .Btn__Add__ {
            padding: 0px 15px;

            margin-right: 5px;

            height: 40px;

            border-radius: 10px;

            background: $PrimaryColor;

            color: $brandWhite;

            border: none;

            font-size: 13px;

            font-weight: 500;

            box-shadow: none;

            white-space: pre-wrap;

            min-width: 150px;
        }

        .Btn__delete__ {
            width: 110px;

            height: 40px;

            border-radius: 10px;

            background: $redButton;

            color: $brandWhite;

            border: none;

            font-size: 18px;

            font-weight: 500;

            margin-left: 20px;

            box-shadow: none;
        }
    }

    > div:nth-child(2) {
        width: 50%;

        display: flex;

        align-items: center;

        justify-content: flex-end;
    }

    .Btn__Transparent {
        width: 60px;

        font-size: 16px;

        height: 40px;

        font-weight: 300;

        background-color: $brandWhite;

        color: $PrimaryColor;

        border: 1px solid $PrimaryColor;

        border-radius: 20px;

        cursor: pointer;

        width: 15%;

        font-family: $titleTextFont;

        padding-right: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $PrimaryColor;

            color: $brandWhite;

            border: 1px solid $PrimaryColor;
        }
    }

    .Btn__Dark {
        font-size: 16px;

        height: 40px;

        font-weight: 500;

        background-color: $PrimaryColor;

        color: $brandWhite;

        border: 1px solid $PrimaryColor;

        border-radius: 20px;

        cursor: pointer;

        width: 15%;

        font-family: $titleTextFont;

        margin-left: 5px;

        span {
            font-weight: 500;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;

            color: $PrimaryColor;

            // border: 1px solid $PrimaryColor;
        }
    }
}

.p-field-addbanner-width {
    width: 48%;
    margin-right: 1%;
}
.p-field-ticker-text-width {
    margin-right: 1%;
    width: 80%;
}
@media (max-width: 767px) {
    .p-field-addbanner-width {
        width: 97%;
        margin-right: 1%;
    }
    .p-field-ticker-text-width {
        width: 97%;
        margin-right: 1%;
    }
}

.desc_Text_Area {
    width: 97%;
}

.addition_Button {
    border-radius: 50%;
    background-color: #13a28a;
    border: none;
    height: 30px;
    width: 30px;
    color: white;
    margin-bottom: 5px;
    cursor: pointer;
}

.reduction_Button {
    border-radius: 50%;
    background-color: #fc6161;
    border: none;
    height: 30px;
    width: 30px;
    color: white;
    cursor: pointer;
}

.desc_Text_Area {
    width: 97%;
}

.add_button_fournine {
    width: 49%;
}

.desc_Text_Area textarea {
    resize: none;
    height: 70px;
}

.resizeNone {
    resize: none;
}

.Btn__Add__Upload {
    cursor: pointer;
    width: 43% !important;
    outline: none;
    border: none;
    background: #13a28a !important;
    color: #f1f1f1;
    min-height: 33.3px;
}

.Btn__Add__Upload__Header {
    cursor: pointer;

    outline: none;
    border: none;
    background: #13a28a !important;
    color: #f1f1f1;
    min-height: 33.3px;
}

.Top__Btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background: $brandWhite;
    padding: 10px;
    border-radius: 10px;

    > div:nth-child(1) {
        width: 100%;
        display: flex;
        align-items: center;

        .Btn__Add__ {
            padding: 0px 15px;
            margin-right: 10px;
            height: 40px;
            border-radius: 10px;
            background: $PrimaryColor;
            color: $secondaryColor;
            border: none;
            font-size: 14px;
            font-weight: 500;
            box-shadow: none;
            margin-left: auto;
        }

        .Btn__delete__ {
            width: 110px;
            height: 40px;
            border-radius: 10px;
            background: $redButton;
            color: $brandWhite;
            border: none;
            font-size: 18px;
            font-weight: 500;
            margin-left: 20px;
            box-shadow: none;
        }
    }

    > div:nth-child(2) {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .Btn__Transparent {
        width: 60px;
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $brandWhite;
        color: $PrimaryColor;
        border: 1px solid $PrimaryColor;
        border-radius: 20px;
        cursor: pointer;
        width: 15%;
        font-family: $titleTextFont;
        padding-right: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $PrimaryColor;
            color: $brandWhite;
            border: 1px solid $PrimaryColor;
        }
    }

    .Btn__Dark {
        font-size: 16px;
        height: 40px;
        font-weight: 500;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        border-radius: 20px;
        cursor: pointer;
        width: 15%;
        font-family: $titleTextFont;
        margin-left: 5px;

        span {
            font-weight: 500;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            // border: 1px solid $PrimaryColor;
        }
    }
}

.Top__Btn__Pricing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background: $brandWhite;
    padding: 10px;
    border-radius: 10px;

    > div:nth-child(1) {
        // width: 50%;
        display: flex;
        align-items: center;

        .Btn__Add__ {
            padding: 0px 15px;
            margin-right: 5px;
            height: 40px;
            border-radius: 10px;
            background: $greenButton;
            color: $brandWhite;
            border: none;
            font-size: 13px;
            font-weight: 500;
            box-shadow: none;
            white-space: pre-wrap;
            min-width: 150px;
        }

        .Btn__delete__ {
            width: 110px;
            height: 40px;
            border-radius: 10px;
            background: $redButton;
            color: $brandWhite;
            border: none;
            font-size: 18px;
            font-weight: 500;
            margin-left: 20px;
            box-shadow: none;
        }
    }

    > div:nth-child(2) {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .Btn__Transparent {
        width: 60px;
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $brandWhite;
        color: $PrimaryColor;
        border: 1px solid $PrimaryColor;
        border-radius: 20px;
        cursor: pointer;
        width: 15%;
        font-family: $titleTextFont;
        padding-right: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $PrimaryColor;
            color: $brandWhite;
            border: 1px solid $PrimaryColor;
        }
    }

    .Btn__Dark {
        font-size: 16px;
        height: 40px;
        font-weight: 500;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        border-radius: 20px;
        cursor: pointer;
        width: 15%;
        font-family: $titleTextFont;
        margin-left: 5px;

        span {
            font-weight: 500;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            // border: 1px solid $PrimaryColor;
        }
    }
}

//
.Btn__PromoCode {
    font-size: 16px;
    height: 40px;
    font-weight: 300;
    background-color: #509793;
    color: #ffffff;
    border: 1px solid #509793;
    border-radius: 5px;
    cursor: pointer;
    width: 180px;
    margin-top: 15px;
    font-family: 'Poppins', sans-serif;
    transition: ease-in-out 0.5s;

    span {
        font-weight: 500;
    }

    &:hover,
    :active,
    :focus {
        background-color: $brandWhite !important;
        color: $PrimaryColor !important;
        border: 1px solid $PrimaryColor !important;
    }
}

.Fetch__Btn {
    width: 100%;
    display: flex;
    align-items: center;

    .Btn__Fetch {
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: #509793;
        color: #ffffff;
        border: 1px solid #509793;
        border-radius: 5px;
        cursor: pointer;
        width: 30%;
        margin-top: 15px;
        font-family: 'Poppins', sans-serif;
        transition: ease-in-out 0.5s;

        span {
            font-weight: 500;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite !important;
            color: $PrimaryColor !important;
            border: 1px solid $PrimaryColor !important;
        }
    }
}

//

.p-datatable-header {
    border-bottom: none !important;
}

.block {
    > i {
        color: $brandWhite !important;
    }

    > input {
        color: $brandWhite;
        background: $PrimaryColor;
    }

    > input:focus {
        border: none !important;
    }

    > input::placeholder {
        color: $brandWhite;
    }
}

.Usergroup__SearchBtn {
    display: flex;
    align-items: flex-end;

    .Btn__Dark__Search {
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        border-radius: 5px;
        cursor: pointer;
        width: 180px;
        font-family: $titleTextFont;
        margin-right: 10px;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;
        }

        .p-button-label {
            font-weight: 300;
            white-space: nowrap;
        }
    }

    .Btn__Transparent__Reset {
        font-size: 15px;
        height: 40px;
        font-weight: 300;
        background-color: $BtnCancelBgColor;
        color: $brandWhite;
        border: 1px solid $BtnCancelBgColor;
        border-radius: 5px;
        cursor: pointer;
        width: 180px;
        font-family: $titleTextFont;
        padding-right: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $BtnCancelBgColor;
            border: 1px solid $BtnCancelBgColor;
        }

        .p-button-label {
            font-weight: 300;
        }
    }
}

.Btn__Trans__St {
    font-size: 14px;
    height: 40px;
    font-weight: 300;
    background-color: $PrimaryColor;
    color: $brandWhite;
    border: 1px solid $PrimaryColor;
    border-radius: 5px;
    cursor: pointer;
    width: 180px;
    font-family: $titleTextFont;
    margin-left: 5px;

    &:hover,
    :active,
    :focus {
        background-color: $brandWhite !important;
        color: $PrimaryColor !important;
        border: 1px solid $PrimaryColor !important;
    }

    .p-button-label {
        font-weight: 300;
        white-space: nowrap;
    }
}

.CenteredAlign__Btn {
    max-width: 50%;
    margin: 0 auto;
}

.CenteredAlign__Okay__Btn {
    max-width: 30%;
    margin: 0 auto;
}

.Down__Btn {
    width: 100%;
    display: flex;
    justify-content: center;

    .Btn__cancel {
        width: 150px !important;
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $BtnCancelBgColor;
        color: $brandWhite;
        border: 1px solid $brandWhite;
        border-radius: 5px;
        cursor: pointer;
        font-family: $titleTextFont;
        padding-right: 5px;
        box-shadow: none !important;
        transition: ease-in-out 0.5s;

        > span {
            color: $brandWhite;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $BtnCancelBgColor;
            border-color: $BtnCancelBgColor;

            > span {
                color: $BtnCancelBgColor;
            }
        }
    }

    .Btn__Add {
        font-size: 16px;
        width: 150px !important;
        height: 40px;
        font-weight: 500;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border-radius: 5px;
        cursor: pointer;
        font-family: $titleTextFont;
        margin-right: 5px;
        border: none;
        box-shadow: none;

        span {
            color: $brandWhite;
            font-weight: 500;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;

            > span {
                color: $PrimaryColor;
            }
        }
    }

    .Btn__Dark__Download {
        font-size: 16px;
        height: 35px;
        font-weight: 500;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        cursor: pointer;
        border-radius: none;
        // margin-right: 22px;
        font-family: $titleTextFont;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
        }
    }

    .Btn__Merchant {
        font-size: 16px;
        width: 250px !important;
        height: 40px;
        font-weight: 500;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border-radius: 5px;
        cursor: pointer;
        font-family: $titleTextFont;
        margin-right: 5px;
        border: none;
        box-shadow: none;

        span {
            color: $brandWhite;
            font-weight: 500;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;

            > span {
                color: $PrimaryColor;
            }
        }
    }
}

// Form pages CSS
.Card__Round {
    border-radius: 25px;

    .Form__Header {
        color: $PrimaryColor;
        // text-align: left;
        font-family: $titleTextFont;
        border-bottom: 1px solid $formHeaderBorder;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .Btn__Dark__OldValue {
            font-size: 16px;
            height: 35px;
            font-weight: bold;
            background-color: $PrimaryColor;
            color: $brandWhite;
            border: 1px solid $PrimaryColor;
            border-radius: 20px;
            margin-top: -10px;
            cursor: pointer;
            width: 200px;
            font-family: $titleTextFont;
            font-size: 16px;
            font-family: 'Poppins', sans-serif;

            &:hover,
            :active,
            :focus {
                background-color: $brandWhite;
                color: $PrimaryColor;
                border: 1px solid $PrimaryColor;

                .p-button-icon {
                    color: $PrimaryColor;
                }
            }

            .p-button-label {
                font-weight: 300;
                white-space: nowrap;
            }
        }

        h1 {
            font-size: 20px;
            font-weight: 500;
        }

        h2 {
            // margin-top: -20px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .Batch_Details_Card {
        border: 1px solid $formHeaderBorder;
    }

    @media (max-width: 767px) {
        .Batch_Details_Card {
            flex-direction: column !important;
        }
    }

    .Batch_Details_Card {
        border: 1px solid $formHeaderBorder;
    }

    @media (max-width: 767px) {
        .Batch_Details_Card {
            flex-direction: column !important;
        }
    }

    .Full__Width {
        width: 100%;
        margin: 20px 0px;
    }

    .Label__Text {
        font-size: 13px;
        font-weight: 500;
        color: $inputLabelColor;
        font-family: $titleTextFont;
        padding-left: 5px;
        float: none;
    }

    .Label__Extra__Text__Padding {
        padding: 0px 15px;
        min-width: 125px;
    }

    .Label__Text__Padding__ApiDailyLimit {
        padding: 0px 20px;
        min-width: 150px;
    }

    .Label__Text__MaxBal {
        font-size: 13px;
        font-weight: 500;
        color: $inputLabelColor;
        font-family: $titleTextFont;
        padding-left: 5px;
        max-width: 140px;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .DeleteImage__Center {
        text-align: center;
    }

    .DeleteLabel__Text {
        text-align: center;
    }

    .maxBalance {
        display: flex;
    }

    .Label__Required {
        color: $validationLabelColor;
        padding-left: 2px;
    }

    .download-File {
        color: #0f8bfd;
    }

    .Input__Round {
        display: block;
        width: 100%;
        padding: 0.375rem 1rem !important;
        font-size: 0.81rem;
        line-height: 2;
        color: $inputFieldColor;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 10px;
        font-family: $titleTextFont;
        border: 1px solid $inputBorder;
        box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

        &:is(&:hover, &:active, &:focus) {
            border: 1px solid $inputBorder;
        }
    }

    .Input__Round__Highlighted {
        display: block;
        width: 100%;
        padding: 0.375rem 1rem !important;
        font-size: 0.81rem;
        line-height: 2;
        color: $inputFieldColor;
        background-color: $brandWhite;
        background-clip: padding-box;
        border: 0;
        border-radius: 10px;
        font-family: $titleTextFont;
        border: 1px solid $validationLabelColor;
        box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

        &:is(&:hover, &:active, &:focus) {
            border: 1px solid $validationLabelColor;
        }
    }

    .Input__Number__Highlighted {
        border: 1px solid $validationLabelColor;
        border-radius: 10px;

        &:is(&:hover, &:active, &:focus) {
            border: 1px solid $validationLabelColor;
        }
    }

    .Input__Round_Upload {
        align-items: center;
        height: 36px;
        display: block;
        width: 100%;
        padding: 2.5px 0.3rem !important;
        padding-left: 13.6px !important;
        font-size: 0.81rem;
        line-height: 2;
        color: $inputFieldColor;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 10px;
        font-family: $titleTextFont;
        border: 1px solid $inputBorder;
        box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

        &:is(&:hover, &:active, &:focus) {
            border: 1px solid $inputBorder;
        }
    }

    .Input__color_Picker {
        display: block;
        width: 100%;
        // padding: 0.375rem 1rem !important;
        padding: 1.4px 0.1rem !important;

        font-size: 0.81rem;
        line-height: 2;
        color: $inputFieldColor;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 10px;
        font-family: $titleTextFont;
        border: 1px solid $inputBorder;
        box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

        &:is(&:hover, &:active, &:focus) {
            border: 1px solid $inputBorder;
        }
    }

    // .Input__Round:focus {
    //     border: none !important;
    // }
    // .pi {
    //     // color: #0880AE;
    // }
    .Image_div {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.375rem 1rem !important;
        font-size: 0.81rem;
        color: $inputFieldColor;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 10px;
        font-family: $titleTextFont;
        border: 1px solid $inputBorder;
        box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

        > p {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        &:is(&:hover, &:active, &:focus) {
            border: 1px solid $inputBorder;
        }

        .Btn__Dark {
            font-size: 16px;
            height: 35px;
            font-weight: 300;
            background-color: $PrimaryColor;
            color: $brandWhite;
            border: 1px solid $PrimaryColor;
            border-radius: 10px;
            cursor: pointer;
            width: 150px;
            font-family: $titleTextFont;
            margin-right: -10px;

            &:hover,
            :active,
            :focus {
                background-color: $brandWhite;
                color: $PrimaryColor;
                border: 1px solid $PrimaryColor;

                .p-button-icon {
                    color: $PrimaryColor;
                }
            }

            .p-button-label {
                font-weight: 300;
                white-space: nowrap;
            }
        }
    }

    .Input__Round__DC {
        display: block;
        width: 100%;
        padding: 0.375rem 1rem !important;
        font-size: 0.81rem;
        line-height: 2;
        color: $inputFieldColor;
        background-color: #fff;
        background-clip: padding-box;
        border: none;
        border-radius: 0px;
        font-family: $titleTextFont;
        border: 1px solid $inputBorder;
        box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

        &:is(&:hover, &:active, &:focus) {
            border: none;
        }

        &::placeholder {
            text-align: center;
        }
    }

    .p-checkbox .p-checkbox-box {
        border: 1px solid #91959c;
    }

    .riskDashboardHeader .p-checkbox {
        margin-left: 0.5rem !important;
    }

    .riskDashboardPage .title__header .p-checkbox .p-checkbox-box {
        border: 1px solid #fff !important;
    }

    // .p-focus {
    //     background: #91959c;
    // }

    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: $PrimaryColor;
        background: $PrimaryColor;
        color: #ffffff;
    }

    .riskDashboardPage .title__header .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #fff !important;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        border-color: $PrimaryColor;
        background: $PrimaryColor;
        color: #ffffff;
    }

    .riskDashboardPage .title__header .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        border-color: #fff !important;
    }

    .Input_Number {
        width: 100%;
        font-size: 0.81rem;
        line-height: 1;
        color: $inputFieldColor;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 10px;
        font-family: $titleTextFont;
        // margin-top: 7px;
        // padding: 0.375rem 0.4rem !important;
        // border: 1px solid $inputBorder;
        box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

        .p-placeholder {
            font-size: 0.81rem;
            // padding: 0.375rem 1rem !important;
        }
    }

    .Dropdown__Round {
        width: 100%;
        font-size: 0.81rem;
        line-height: 1;
        color: $inputFieldColor;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 10px;
        font-family: $titleTextFont;
        // margin-top: 7px;
        padding: 0.375rem 0.4rem !important;
        border: 1px solid $inputBorder;
        box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

        .p-placeholder {
            font-size: 0.81rem;
            // padding: 0.375rem 1rem !important;
        }
    }

    .Dropdown__Round__Highlighted {
        width: 100%;
        font-size: 0.81rem;
        line-height: 1;
        color: $inputFieldColor;
        background-color: $brandWhite;
        background-clip: padding-box;
        border: 0;
        border-radius: 10px;
        font-family: $titleTextFont;
        // margin-top: 7px;
        padding: 0.375rem 0.4rem !important;
        border: 1px solid $validationLabelColor;
        box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

        .p-placeholder {
            font-size: 0.81rem;
            // padding: 0.375rem 1rem !important;
        }
    }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #4338ca;
    background: #eef2ff;
}

.p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    font-size: 0.81rem;
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0.75rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    color: #44486d;
    background: transparent;
    margin: 0;
    padding: 0.75rem 1.25rem !important;
    border: 0 none;
}

.p-dropdown-items-wrapper {
    overflow: auto;
    background: $brandWhite;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.TextArea__Round {
    width: 100%;
    font-size: 0.81rem;
    // line-height: 1;
    color: $inputFieldColor;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 0;
    border-radius: 10px;
    font-family: $titleTextFont;
    margin-top: 7px;
    padding: 0.375rem 0.4rem !important;
    border: 1px solid $inputBorder;
    box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

    &:is(&:hover, &:active, &:focus) {
        border: 1px solid $inputBorder;
    }
}

.TextArea__Round__Highlighted {
    width: 100%;
    font-size: 0.81rem;
    // line-height: 1;
    color: $inputFieldColor;
    background-color: $brandWhite;
    background-clip: padding-box;
    border: 0;
    border-radius: 10px;
    font-family: $titleTextFont;
    margin-top: 7px;
    padding: 0.375rem 0.4rem !important;
    border: 1px solid $validationLabelColor;
    box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

    &:is(&:hover, &:active, &:focus) {
        border: 1px solid $validationLabelColor;
    }
}

.Down__Btn__Start {
    text-align: start;
    margin-top: 19px;

    @media (max-width: 390px) {
        display: flex;
    }

    .Btn__Transparent {
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $brandWhite;
        color: $PrimaryColor;
        border: 1px solid $PrimaryColor;
        border-radius: 10px;
        cursor: pointer;
        width: 150px;
        font-family: $titleTextFont;
        padding-right: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $PrimaryColor;
            color: $brandWhite;
            border: 1px solid $PrimaryColor;
        }

        .p-button-label {
            font-weight: 300;
        }
    }

    .Btn__Dark {
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        border-radius: 10px;
        cursor: pointer;
        width: 150px;
        font-family: $titleTextFont;
        margin-left: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;

            .p-button-icon {
                color: $PrimaryColor;
            }
        }

        .p-button-label {
            font-weight: 300;
            white-space: nowrap;
        }
    }

    > span {
        color: $PrimaryColor;
    }
}

.Down__Btn__End {
    text-align: end;
    margin-top: 5px;

    @media (max-width: 390px) {
        display: flex;
    }

    .Btn__Transparent {
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $brandWhite;
        color: $PrimaryColor;
        border: 1px solid $PrimaryColor;
        border-radius: 10px;
        cursor: pointer;
        width: 150px;
        font-family: $titleTextFont;
        padding-right: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $PrimaryColor;
            color: $brandWhite;
            border: 1px solid $PrimaryColor;
        }

        .p-button-label {
            font-weight: 300;
        }
    }

    .Btn__Dark {
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        border-radius: 10px;
        cursor: pointer;
        width: 150px;
        font-family: $titleTextFont;
        margin-left: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;

            .p-button-icon {
                color: $PrimaryColor;
            }
        }

        .p-button-label {
            font-weight: 300;
            white-space: nowrap;
        }
    }

    > span {
        color: $PrimaryColor;
    }
}

.title__header {
    background-color: $PrimaryColor;
    color: $brandWhite;
    border: 10px solid $PrimaryColor;
    border-radius: 5px;
    width: 100%;
}

.p-dialog-draggable .p-dialog-header {
    cursor: move;
    background: $brandWhite;
}

.p-dialog .p-dialog-content {
    background: $brandWhite;
    color: #44486d;
    padding: 0 1.714rem 1.714rem 1.714rem;
}

// Data table Header with Search

.Global__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h5 {
        color: $PrimaryColor !important;
        font-weight: 600 !important;
        font-family: $titleTextFont;
    }

    .p-input-icon-left > i:first-of-type,
    .p-input-icon-left > svg:first-of-type,
    .p-input-icon-left > .p-input-prefix {
        left: 0.7rem;
        color: $PrimaryColor;
        top: 1.7rem;
        font-size: 12px;
    }

    .p-inputtext {
        text-indent: 0px;
    }

    .p-inputtext::-webkit-input-placeholder {
        padding-left: 0rem;
    }
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: transparent;
    border-color: $PrimaryColor;
    color: $PrimaryColor;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: transparent;
    border-color: $PrimaryColor;
    color: $PrimaryColor;
}

.Header_items {
    width: 33%;
    display: flex;
    justify-content: right;
    border: none;
    max-width: 400px;
}

.search_section {
    width: 50%;
    display: flex;
    align-items: center;

    > input {
        height: 30px;
        width: 180px !important;
        border: none;
        border-bottom: 1px solid $inputBorder !important;
        background: none;
        padding-left: 10px;
        margin-left: 10px;
    }

    > input:focus {
        outline: none;
    }
}

.details_section {
    width: 22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;

    > img {
        cursor: pointer;
    }
}

.pi-power-off {
    color: $PrimaryColor;
    font-size: 1.5rem;
    cursor: pointer;
}

.pi-bell {
    color: $PrimaryColor;
    font-size: 1.5rem;
    cursor: pointer;
}

.pi-search {
    color: $brandWhite;
    font-size: 1rem;
    cursor: pointer;
}

@media (max-width: 1024px) and (min-width: 768px) {
    .layout-breadcrumb-container {
        display: flex;
        flex-direction: column;
    }

    .Header_items {
        width: 100% !important;
        display: flex;
    }

    .search_section {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 20px;

        > i {
            display: none !important;
        }

        > input {
            height: 30px;
            width: 80% !important;
            border: none;
            border-bottom: 1px solid $inputBorder !important;
            background: none;
            padding-left: 10px;
            margin-left: 10px;
        }

        > input:focus {
            outline: none;
        }
    }

    .details_section {
        display: none !important;
    }

    .layout-breadcrumb-left-items {
        width: 100%;
        display: flex;
        justify-content: flex-start !important;
    }
}

@media (max-width: 767px) {
    .layout-breadcrumb-container {
        display: flex;
        flex-direction: column;
    }

    .Header_items {
        width: 100% !important;
        display: flex;
    }

    .search_section {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 20px;

        > i {
            display: none !important;
        }

        > input {
            height: 30px;
            width: 80% !important;
            border: none;
            border-bottom: 1px solid $inputBorder !important;
            background: none;
            padding-left: 10px;
            margin-left: 10px;
        }

        > input:focus {
            outline: none;
        }
    }

    .details_section {
        display: none !important;
    }

    .layout-breadcrumb-left-items {
        width: 100%;
        display: flex;
        justify-content: flex-start !important;
    }
}

.update__button{
    width: 140px;
    height: 45px;
    border-radius: 10px;
    border: none !important;
    background: $PrimaryColor;
    color: $secondaryColor;
    box-shadow: none !important;
    font-size: 18px;
    font-weight: 600;
}
.update__button:hover{
    background: $PrimaryColor !important;
        color: $secondaryColor !important;
        box-shadow: none !important;
}

.cancel__button {
    width: 140px;
    height: 45px;
    border-radius: 10px;
    background: none;
    color: $PrimaryColor;
    border: 1px solid $secondaryColor;
    box-shadow: none !important;
}


//For active route changes

.app-menu-item.active-route i,
.app-menu-item.active-route span {
    color: $secondaryColor !important;
}

///welcome zbox
.welcome-zbox {
    padding: 200px 0px;
    text-align: center;
    color: #004890;
    font-weight: bold;
    font-size: 50px;
}

.Zbox__Heading {
    padding-left: 20px;
    color: $PrimaryColor;
    font-weight: 500;
    padding-top: 5px;
}

.p-badge {
    min-width: 1.3rem;
    height: 1.3rem;
    line-height: 1.3rem;
}

.centered__login {
    text-align: center;
    background: none !important;
    width: 450px !important;
    margin: 0 auto !important;
    padding: 0px 30px !important;
    // padding: 0px 60px !important;
    border-radius: 20px;
}

.captcha-color {
    background: none !important;
    padding: 16px 0px !important;
    margin-bottom: 0px !important;
}

@media (max-width: 767px) {
    // .centered__login{
    //     width:90% !important;
    // }

    // .login-body .login-panel .form-container input {
    //     max-width: 100% !important;
    //     min-width: 100% !important;
    // }

    // .login-body .login-panel .button-container button {
    //     max-width: 100% !important;
    //     min-width: 100% !important;
    // }
}

@media (max-width: 425px) {
    .centered__login {
        width: 90% !important;
    }

    .captcha-color {
        div:first-child {
            div:first-child {
                width: 90% !important;
                margin: 0 auto;
            }
        }
    }

    .login-body .login-panel .form-container input {
        max-width: 80% !important;
        min-width: 80% !important;
        margin: 0 auto;
    }

    .login-body .login-panel .button-container button {
        max-width: 80% !important;
        min-width: 80% !important;
        margin: 0 auto;
        margin-bottom: 15px;
    }
}

// Form Down Button CSS

.Down__Btn {
    text-align: center;

    @media (max-width: 390px) {
        display: flex;
    }

    .Btn__Transparent {
        font-size: 15px;
        height: 40px;
        font-weight: 300;
        background-color: $BtnCancelBgColor;
        color: $brandWhite;
        border: 1px solid $BtnCancelBgColor;
        border-radius: 5px;
        cursor: pointer;
        width: 140px;
        font-family: $titleTextFont;
        padding-right: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $BtnCancelBgColor;
            border: 1px solid $BtnCancelBgColor;
        }

        .p-button-label {
            font-weight: 300;
        }
    }

    .Btn__Dark {
        font-size: 15px;
        height: 40px;
        font-weight: 300;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        border-radius: 5px;
        cursor: pointer;
        width: 140px;
        margin-right: 10px;
        font-family: $titleTextFont;
        margin-left: 5px;
        transition: ease-in-out 0.5s;

        .pi-search {
            padding-left: 10px !important;
            margin-right: 0rem !important;
        }

        &:hover,
        :active {
            background-color: $brandWhite;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;

            .pi-search {
                color: $PrimaryColor;
                font-size: 1rem;
                cursor: pointer;
            }
        }

        &:focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            border-color: $PrimaryColor;

            .pi-search {
                color: $PrimaryColor;
                font-size: 1rem;
                cursor: pointer;
            }
        }

        .p-button-label {
            font-weight: 300;
            white-space: nowrap;
        }
    }
}

.Down__Btn__Center {
    text-align: center;

    @media (max-width: 390px) {
        display: flex;
    }

    .Btn__Transparent {
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $brandWhite;
        color: $PrimaryColor;
        border: 1px solid $PrimaryColor;
        border-radius: 20px;
        cursor: pointer;
        width: 150px;
        font-family: $titleTextFont;
        padding-right: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $PrimaryColor;
            color: $brandWhite;
            border: 1px solid $PrimaryColor;
        }

        .p-button-label {
            font-weight: 300;
        }
    }

    .Btn__Dark {
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        border-radius: 20px;
        cursor: pointer;
        width: 150px;
        font-family: $titleTextFont;
        margin-left: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;
        }

        .p-button-label {
            font-weight: 300;
            white-space: nowrap;
        }
    }
}

.p-checkbox .p-checkbox-box {
    border: 1px solid #bcbcbc;
    background: rgba(68, 72, 109, 0.07);
    width: 20px;
    height: 20px;
    color: #44486d;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.riskDashboardPage .title__header .p-checkbox .p-checkbox-box {
    border: 1px solid #fff !important;
}

.Remember__Forget {
    justify-content: space-between;
    margin-top: 10px;

    @media (max-width: 428px) {
        justify-content: center;
    }
}

.Margin__Top {
    @media (max-width: 428px) {
        margin-top: 1rem;
    }
}

.checkbox_login {
    @media (max-width: 428px) {
        margin-left: 5px;
    }
}

/// topbar css
.layout-breadcrumb-container {
    background-color: white;
    padding: 30px;
}


/// sidebar css
.layout-wrapper.layout-sidebar .layout-menu-wrapper {
    background: white;
}

/// sidebarbar css
.layout-menu-wrapper {
    border-radius: 0px 0px 40px 0px;
}

// Side Icon CSS
.layout-config-button {
    display: block;
    position: fixed;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    background: #0f8bfd !important;
    color: #ffffff;
    text-align: center !important;
    top: 50%;
    right: 0;
    border-radius: 0;
    margin-top: -1.5rem !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    transition: background-color 0.2s;
    overflow: hidden;
    display: none;
    cursor: pointer;
    z-index: 999;
    box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.15);
}

.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    color: $PrimaryColor;
    font-family: $titleTextFont;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: $brandWhite;
    color: #44486d;
    padding: 1.286rem 1.714rem;
}

@media (min-width: 992px) {
    .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li > a.active-route,
    .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > a.active-route {
        background-color: $PrimaryColor;
        color: $brandWhite;

        &:is(&:hover, &:focus, &:active) {
            background-color: $PrimaryColor;
            color: $brandWhite;
        }
    }

    .layout-wrapper.layout-overlay .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li > a.active-route,
    .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > ul li > a.active-route {
        background-color: $PrimaryColor;
        color: $brandWhite;

        &:is(&:hover, &:focus, &:active) {
            background-color: $PrimaryColor;
            color: $brandWhite;
        }
    }
}

.DownDelete__Btn {
    border-top: 0 none;
    background: #ffffff;
    color: #495057;
    // padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: center;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-top: 22px;
    margin-bottom: -25px;

    // .Btn__Transparent {
    //     font-size: 15px;
    //     height: 40px;
    //     font-weight: 300;
    //     background-color: $BtnCancelBgColor;
    //     color: $brandWhite;
    //     border: 1px solid $BtnCancelBgColor;
    //     border-radius: 5px;
    //     cursor: pointer;
    //     width: 140px;
    //     font-family: $titleTextFont;
    //     padding-right: 5px;

    //     &:hover,
    //     :active,
    //     :focus {
    //         background-color: $brandWhite;
    //         color: $BtnCancelBgColor;
    //         border: 1px solid $BtnCancelBgColor;
    //     }

    //     .p-button-label {
    //         font-weight: 300;
    //     }
    // }

    .Btn__Transparent {
        font-size: 16px;
        height: 50px;
        font-weight: 500;
        background-color: $BtnCancelBgColor;
        color: $brandWhite;
        border: 1px solid $BtnCancelBgColor;
        border-radius: 5px;
        cursor: pointer;
        width: 34%;
        font-family: $titleTextFont;
        padding-right: 5px;
        margin-left: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $BtnCancelBgColor;
            border: 1px solid $BtnCancelBgColor;
        }
    }

    .Btn__Dark {
        font-size: 16px;
        height: 50px;
        font-weight: 500;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        border-radius: 5px;
        cursor: pointer;
        width: 34%;
        font-family: $titleTextFont;

        margin-right: 20px;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            // border: 1px solid $PrimaryColor;
        }
    }

    .Btn__Dark__Ok {
        font-size: 16px;
        height: 50px;
        font-weight: 500;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        border-radius: 5px;
        cursor: pointer;
        width: 34%;
        font-family: $titleTextFont;
        margin-left: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            // border: 1px solid $PrimaryColor;
        }
    }
}

.DeleteImage__Center {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    margin-left: 0px;

    img {
        position: relative;
        top: -57px;
        left: -40px;
    }
}

.__heading_ {
    font-size: 16px;
    color: $PrimaryColor;
    font-weight: 700;
    font-family: $titleTextFont;
}

//transaction wise limit cards

.cards_section___ {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;

    .Debit_card {
        width: 48%;
        height: auto;
    }

    .credit_card {
        width: 48%;
        height: auto;
    }

    .card_heading__ {
        font-size: 16px;
        color: #000;
        font-weight: 500;
    }

    .ca_rds {
        width: 100%;
        height: auto;
        padding: 10px;
        border: 1px solid $inputBorder;
        border-radius: 10px;

        .left_section__ {
            width: 48%;
        }

        .right_section__ {
            width: 48%;
            padding-top: 49px;
        }
    }
}

//transactionlimitchecker

.comment_section__ {
    width: 100%;
    height: auto;
    margin-top: 40px;
    background: #cecece;
    padding: 10px;
}

.Btn__Transparent {
    width: 60px;
    font-size: 16px;
    height: 40px;
    font-weight: 300;
    background-color: $brandWhite;
    color: $PrimaryColor;
    border: 1px solid $PrimaryColor;
    border-radius: 10px;
    cursor: pointer;
    width: 15%;
    font-family: $titleTextFont;
    padding-right: 5px;

    &:hover,
    :active,
    :focus {
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
    }
}

//pick list css

.p-picklist-source-controls {
    display: none !important;
}

.p-picklist-target-controls {
    display: none !important;
}

.p-picklist-source {
    width: 400px;
}

.p-picklist-target {
    width: 400px;
}

.KycSets__Picklist {
    .p-picklist-source {
        width: 345px;
    }

    .p-picklist-target {
        width: 345px;
    }
}

.Picklist__Disabled {
    .p-picklist-source {
        width: 345px;
    }

    .p-picklist-target {
        width: 345px;
    }

    .p-picklist-buttons button {
        pointer-events: none;
        opacity: 0.5;
    }
}

// .picklist-disabled .p-picklist-target-wrapper {
//     pointer-events: none;
//     opacity: 0.5;
// }

.picklist-disabled .p-picklist-buttons button {
    pointer-events: none;
    opacity: 0.5;
}

.p-button-icon-only {
    background-color: $PrimaryColor;
    color: $brandWhite;
    // padding: 0.829rem 2rem !important;
    font-size: 1.5rem !important;

    &:is(&:hover, &:active, &:focus) {
        background-color: $PrimaryColor;
        color: $brandWhite;
        // padding: 0.829rem 2rem !important;
        font-size: 1.5rem !important;
    }
}

.p-button-danger {
    background-color: #fc6161;

    &:is(&:hover, &:active, &:focus) {
        background-color: #fc6161;
        font-size: 1.5rem !important;
    }
}

.Debit__Heading {
    display: flex;
    border: 1px solid $inputBorder;
    width: 85%;
    color: #8a8ea6;

    div {
        width: 33.5%;
        border-right: 1px solid $inputBorder;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.DebitValue__Heading {
    display: flex;
    border: 1px solid $inputBorder;
    width: 85%;

    div {
        height: 35px;
        display: flex;
        justify-content: center;
        width: 33.4%;
        align-items: center;
        border: 1px solid $inputBorder;
    }
}

.p-picklist-filter-input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
}

// Account Tabs

.p-unselectable-text {
    border-radius: 6px;
    background-color: #cacaca;
}

.custom-tabs {
    padding: 25px 60px;
    cursor: pointer;
}

.p-tabview-selected {
    background: #14a38b;
    color: #fff;
    transition: 0.5s;
}

.p-tabview .p-tabview-nav {
    border: none;
}

.p-tabview-nav {
    justify-content: center;
}

.object-contain {
    object-fit: contain;
}

.login-body {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: $PrimaryColor;
    .login-panel {
        .form-container {
            .text-captcha {
                border: 1px dashed #e6e6e6;
                border-radius: 10px;
                padding: 1.5rem;
                > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                #reload_href {
                    display: none;
                    // color: #fff !important;
                    // cursor: pointer !important;
                    // text-decoration: underline !important;
                    // font-size: 1rem !important;
                    // font-weight: 600 !important;
                }
            }
            input {
                max-width: none !important;
            }
            .p-input-icon-left {
                i {
                    top: 32px !important;
                }
            }
        }
        .button-container {
            button {
                display: flex;
                // max-width: 320px;
                max-width: none;
                margin-bottom: 32px;
                margin-right: 10px !important;
            }
        }
    }
}

.image__section{
    display: flex;
    justify-content: center;
    align-items: center;
    >img{
        width: 80%;
        height: 80%;
    }
}
.data__section{
    display: flex;
    justify-content: center;
    align-items: center;

}

.DownloadIBtn__Div {
    display: flex;
    flex-direction: column;
}

.Customer__Doc__Hide {
    display: none;
}

.field-edit-icon {
    background-color: #14a38b;
    color: #ffffff;
    border-radius: 50px;
    font-size: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.field-eye-icon {
    background-color: #f2ac57;
    color: #ffffff;
    border-radius: 50px;
    font-size: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

// .p-dropdown {
//     width: 100%;
// }

.secondary-color {
    color: #14a38b;
}

.secondary-bg {
    background-color: #14a38b;
}

.dark-bg {
    background-color: #535965;
}

// View button

.view-button {
    background-color: #14a38b;
    border: 1px solid #14a38b;
    padding: 7px 20px;
    width: 125px !important;
}

.view-button:enabled:hover {
    background: #14a38b;
    color: #ffffff;
    border-color: #14a38b;
}

.view-button:enabled:active {
    background-color: #14a38b;
}

.card {
    margin-bottom: 16px;
}

.custom-card {
    background: #f8f8f8 !important;
    padding: 46px;
}

.custom-card thead {
    border-top: 1px solid rgba(68, 72, 109, 0.17);
}

.edit-ac-buttons {
    background-color: #14a38b;
    color: #ffffff;
    padding: 15px 20px;
    border-radius: 6px;
    margin-right: 4px;
    width: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: padding 0.3s;
    /* Adding transition for smooth effect */
}

.edit-ac-buttons:hover {
    cursor: pointer;
    border: 1px solid $PrimaryColor;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: $brandWhite;
    color: $PrimaryColor;
}

.p-dialog .p-dialog-footer {
    background: #ffffff;
}

// CUSTOM IMAGE UPLOADER

.file-input-container {
    position: relative;
    display: inline-block;
}

.file-input-field {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
}

.file-upload-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.w-100 {
    width: 100%;
}

.field-loader {
    .css-50etie {
        margin: 4px auto 10px auto !important;
        width: 30px !important;
    }
}

.custom-table table {
    border-collapse: collapse;
    width: 100%;
}

.custom-table table th {
    padding: 20px 0;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    font-weight: 500;
}

.custom-table table td {
    padding: 15px;
}

.dim-heading {
    color: #b5b5b5;
}

.p-disabled,
.p-component:disabled {
    opacity: 0.8 !important;
}

.selfie-styling {
    position: relative;
    display: inline-block;
}

.selfie-styling i {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    cursor: pointer;
}

.p-dialog-enter-done {
    @media (max-width: 428px) {
        width: 100vw !important;
    }
}

//////////////////inputSwitch styling///////////////////////////////
.p-inputswitch {
    .p-inputswitch-slider {
        border-radius: 5px !important;
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            background: $PrimaryColor;
        }

        &:not(.p-disabled):hover {
            .p-inputswitch-slider {
                background: #14a38b;
            }
        }
    }
}

//////////////////RadioButton styling/////////////////////////////////
.p-radiobutton {
    .p-radiobutton-box {
        &.p-highlight {
            border-color: $PrimaryColor !important;
            background: $PrimaryColor !important;
        }
    }
}

.Card__Bg__Change {
    background: $CardBgColor;
}

.img-viewer {
    position: absolute;
    top: 59%;
    right: 8px;
    transform: translateY(-50%);
    cursor: pointer;
}

.img-viewer img {
    width: 30px;
    height: 30px;
}

.img-viewer:hover > .p-image-preview-indicator {
    opacity: 0 !important;
}

.img-viewer .p-image-preview-indicator i {
    display: none;
}

.custom-datatable td {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    white-space: nowrap;
}

////////////////////////multiSelect Styling///////////////////////////////

.steric {
    color: red;
    padding-left: 3px;
}

.p-fluid .p-inputnumber .p-inputnumber-input {
    width: 100%;
    padding: 0.375rem 1rem !important;
    font-size: 0.81rem;
    line-height: 2;
    color: $inputFieldColor;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 10px;
    font-family: $titleTextFont;
    border: 1px solid $inputBorder;
    box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

    &:is(&:hover, &:active, &:focus) {
        border: 1px solid $inputBorder;
    }
}

/* YourComponent.css */
.Disabled__AccountMaintenance {
    opacity: 0.5;
    /* Or any other styling that indicates the element is disabled */
    pointer-events: none;
    /* Prevent interactions */
    cursor: not-allowed;
    /* Show not-allowed cursor */
}

.Down__Btn__Configuration {
    width: 100%;
    display: flex;
    justify-content: left;

    .Btn__cancel {
        width: 150px !important;
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $BtnCancelBgColor;
        color: $brandWhite;
        border: 1px solid $brandWhite;
        border-radius: 5px;
        cursor: pointer;
        font-family: $titleTextFont;
        padding-right: 5px;
        box-shadow: none !important;
        transition: ease-in-out 0.5s;

        > span {
            color: $brandWhite;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $BtnCancelBgColor;
            border-color: $BtnCancelBgColor;

            > span {
                color: $BtnCancelBgColor;
            }
        }
    }

    .Btn__Add {
        font-size: 16px;
        width: 150px !important;
        height: 40px;
        font-weight: 500;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border-radius: 5px;
        cursor: pointer;
        font-family: $titleTextFont;
        margin-right: 5px;
        border: none;
        box-shadow: none;

        span {
            color: $brandWhite;
            font-weight: 500;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;

            > span {
                color: $PrimaryColor;
            }
        }
    }

    .Btn__Dark__Download {
        font-size: 16px;
        height: 35px;
        font-weight: 500;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        cursor: pointer;
        border-radius: none;
        margin-right: 22px;
        font-family: $titleTextFont;

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;
            color: $PrimaryColor;
        }
    }
}

.Label__LineHeight {
    line-height: 6;
}

.Api__Header__Table {
    margin: 40px 0px 20px;
    background-color: $brandWhite;
    border-radius: 5px;
    padding: 10px 0px;
}

////////////////////////multiSelect Styling///////////////////////////////
.p-multiselect-items {
    .p-multiselect-item {
        &.p-highlight {
            color: $brandWhite;

            background: $PrimaryColor !important;
        }

        .p-checkbox .p-checkbox-box.p-highlight {
            background: $PrimaryColor !important;
        }
    }
}

.p-multiselect {
    &.p-multiselect-chip {
        .p-multiselect-token {
            background: $PrimaryColor;

            color: $brandWhite;
        }
    }
}

.DataTable__Add__Btn {
    display: flex;
    align-items: flex-end;
}

.Inputfield__Fulllength {
    width: 100%;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.571rem;
    margin-right: -1.571rem;
    background: $brandWhite;
}

.img-viewer-ultra img {
    width: 35px;
    height: 35px;
}

.img-viewer-ultra:hover > .p-image-preview-indicator {
    opacity: 0 !important;
}

.img-viewer-ultra .p-image-preview-indicator i {
    display: none;
}

.Campaign__Steps,
.Lending__Steps,
.CustomerRisk__Steps {
    ul {
        .p-steps-item {
            &::before {
                border-top: 10px solid $PrimaryColor !important;
                width: 40% !important;
                border-radius: 3px;
                left: 15% !important;
            }
        }

        .p-steps-item.p-highlight.p-steps-current.p-disabled {
            &::before {
                border-top: 10px solid $PrimaryColor !important;
                width: 40% !important;
                border-radius: 3px;
                left: 15% !important;
            }
        }

        .p-steps-item.p-disabled {
            &::before {
                border-top: 10px solid lightgrey !important;
                width: 40% !important;
                border-radius: 3px;
                left: 15% !important;
            }
        }

        > li {
            margin-left: -150px;
            // &::before {
            //     border-top: 10px solid !important;
            //     width: 40% !important;
            //     border-radius: 3px;
            //     left: 15% !important;
            // }
        }

        li:first-child::before {
            // content: none;
            display: none;
        }
    }

    .p-steps-item {
        a {
            position: absolute !important;
            right: 40px !important;
            top: -40px !important;
            left: 170px;

            .p-steps-number {
                width: 25px;
                height: 25px;
                padding: 25px;
                border-radius: 50% !important;
                color: $brandWhite !important;

                &:active {
                    background: $PrimaryColor !important;
                }
            }

            &:hover {
                .p-steps-number {
                    color: $brandWhite !important;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .Campaign__Steps,
    .Lending__Steps,
    .CustomerRisk__Steps {
        ul {
            .p-steps-item {
                &::before {
                    border-top: 10px solid $PrimaryColor !important;
                    width: 25% !important;
                    border-radius: 3px;
                    left: 30% !important;
                }
            }

            .p-steps-item.p-highlight.p-steps-current.p-disabled {
                &::before {
                    border-top: 10px solid $PrimaryColor !important;
                    width: 25% !important;
                    border-radius: 3px;
                    left: 30% !important;
                }
            }

            .p-steps-item.p-disabled {
                &::before {
                    border-top: 10px solid lightgrey !important;
                    width: 25% !important;
                    border-radius: 3px;
                    left: 30% !important;
                }
            }
        }
    }
}
.Download__Link {
    padding-left: 10px;
}

.Vertical__ResizeTextArea {
    resize: vertical;
    /* Enable vertical resizing */
}

.main_holder {
    text-align: center;
    border: 1px solid #d1d1d1;
    padding: 10px;
}

.img_card {
    padding: 20px;
}

.img_card img {
    width: 100%;
}

.Custom__MessageDialog .p-dialog-header .p-dialog-header-icons {
    visibility: hidden;
}

.withoutArrow input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.withoutArrow input[type='number']::-webkit-inner-spin-button,
.withoutArrow input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
}

.hra_head {
    background-color: #14a38b;
    border-radius: 4px;
    margin-bottom: 20px;
}

.hra_head h5 {
    margin: 0;
    padding: 15px 10px;
    color: white;
}

.hra_details h5 {
    color: #14a38b;
}

.hra_table table {
    width: 100%;
}

.hra_table table,
th,
td {
    border: 1px solid #e6e6e6;
    border-collapse: collapse;
}

.hra_table table tr td,
.hra_table table tr th {
    padding: 15px;
    text-align: center;
}

.p-steps .p-steps-item.p-highlight .p-menuitem-link .p-steps-title {
    color: #44486d;
    font-weight: bold;
}

.p-steps .p-steps-item.p-highlight .p-menuitem-link .p-steps-number {
    background: $PrimaryColor;
    color: $brandWhite;
    box-shadow: 0px 0px 10px rgba(15, 139, 253, 0.25);
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    background: $PrimaryColor;
    color: $brandWhite;
}

.p-steps .p-steps-item.p-disabled .p-menuitem-link .p-steps-number {
    background: rgba(68, 72, 109, 0.12);
    color: $brandWhite;
}

.p-organizationchart-table .red-node {
    background-color: #fceaea;
    color: black;
    /* Text color */
    border: 1px solid #ff7171;
    border-radius: 10px;
    padding: 10px;
    /* Other styles for the first div */
}

/* Green Node */
.p-organizationchart-table .green-node {
    background-color: #f0faf0;
    color: black;
    border: 1px solid #12a189;
    border-radius: 10px;
    padding: 10px;

    // background-color: green;
    // color: white; /* Text color */
}

.p-organizationchart-table > tbody > tr > td {
    border: none;
}

// .p-organizationchart-table .green-node > tbody > tr > td > div:nth-child(1)> div> .p-organizationchart-node-content >.green-node {
//     border: 1px solid #12A189; /* Green border */
//     border-radius: 10px;
//     /* Other styles for the green node */
// }

// /* Red Node */
// .p-organizationchart-table .red-node > tbody > tr > td > div:nth-child(1) {
//     border: 1px solid red; /* Red border */
//     border-radius: 10px;
//     /* Other styles for the red node */
// }

/* Common styles for both red and green nodes */
.p-organizationchart-table > tbody > tr > td > div:nth-child(1) {
    border: none;
    // border: 1px solid #12A189;
    /* Other styles for the first div */
}

//lending management dialog
.dialog-demo .p-button {
    margin: 0 0.5rem 0 0;
    min-width: 10rem;
}

.dialog-demo p {
    margin: 0;
    line-height: 1.5;
}

.dialog-demo .p-dialog .p-button {
    min-width: 6rem;

    .Radio__Btn {
        display: flex;
        margin-top: 8px;
        font-family: $titleTextFont;

        .field-radiobutton {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            padding-right: 20px;
        }
    }

    .Centre_Aligned_Buttons {
        width: 100%;
        display: flex;
        justify-content: center;

        .Light_Button {
            width: 10vw;
            border-radius: 5px;
            background: #eef5f4;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;
            box-shadow: none;
            margin-left: 10px;
            font-weight: 500;
            font-size: 16px;
        }

        .Light_Button:hover {
            background: none;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;
        }

        .DarkGray_Button {
            width: 10vw;
            border-radius: 5px;
            background: #e1e1e1;
            color: #a2a2a2;
            border: none;
            box-shadow: none;
            margin-left: 10px;
            font-weight: 500;
            font-size: 16px;
        }

        .DarkGray_Button:hover {
            background: none;
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;
        }
    }

    .Btn__Dark {
        font-size: 16px;
        height: 40px;
        font-weight: 500;
        background-color: $PrimaryColor;
        color: $brandWhite;
        border: 1px solid $PrimaryColor;
        border-radius: 20px;
        cursor: pointer;
        width: 15%;
        font-family: $titleTextFont;
        margin-left: 5px;

        span {
            font-weight: 500;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;

            color: $PrimaryColor;

            // border: 1px solid $PrimaryColor;
        }
    }

    .Black__Btn {
        width: 100px;
        border-radius: 5px;
        background: #535965;
        color: #fff;
        border: none;
        box-shadow: none;
        margin-left: 10px;
        font-weight: 500;
        font-size: 16px;
    }
}

.Centre_Aligned_Buttons {
    width: 100%;
    display: flex;
    justify-content: center;

    .Light_Button {
        width: 15vw;
        border-radius: 5px;
        background: #eef5f4;
        color: $PrimaryColor;
        border: 1px solid $PrimaryColor;
        box-shadow: none;
        margin-left: 10px;
        font-weight: 500;
        font-size: 16px;
    }

    .Light_Button:hover {
        background: none;
        color: $PrimaryColor;
        border: 1px solid $PrimaryColor;
    }

    .DarkGray_Button {
        width: 15vw;
        border-radius: 5px;
        background: #e1e1e1;
        color: #a2a2a2;
        border: none;
        box-shadow: none;
        margin-left: 10px;
        font-weight: 500;
        font-size: 16px;
    }

    .DarkGray_Button:hover {
        background: none;
        color: $PrimaryColor;
        border: 1px solid $PrimaryColor;
    }
}

.Btn__Dark {
    font-size: 16px;
    height: 40px;
    font-weight: 500;
    background-color: $PrimaryColor;
    color: $brandWhite;
    border: 1px solid $PrimaryColor;
    border-radius: 20px;
    cursor: pointer;
    width: 15%;
    font-family: $titleTextFont;
    margin-left: 5px;

    span {
        font-weight: 500;
    }

    &:hover,
    :active,
    :focus {
        background-color: $brandWhite;
        color: $PrimaryColor;
        // border: 1px solid $PrimaryColor;
    }
}

.Black__Btn {
    width: 100px;
    border-radius: 5px;
    background: #535965;
    color: #fff;
    border: none;
    box-shadow: none;
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
}

.custombar1 .p-tree {
    border: none !important;
}

.Heading__With__Btn {
    display: flex;
    justify-content: space-between;

    .Down__Btn {
        width: 10% !important;
    }
}

.Btn__Margin {
    margin: 0px;
}

.total {
    margin: 0px;
    color: #000;
}

.traingle_main {
    position: absolute;
    left: 45%;
    top: 40%;
    text-align: center;
}

.traingle_main span {
    font-size: 100px;
    opacity: 0.3;
}

.traingle_main h5 {
    margin: 0;
    padding-top: 10px;
    opacity: 0.5;
}

@media (min-width: 786px) {
    .custombar1 {
        width: 100%;
        height: 70vh;
    }
}

.tree_head {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
}

.button-row {
    display: flex;
    gap: 10px;
    /* Adjust the padding between buttons as needed */
}

.button-row button {
    padding: 5px 10px;
    /* Adjust the padding for the buttons as needed */
}

.Btn__Withdraw {
    padding: 0px 15px;
    margin-right: 10px;
    height: 40px;
    border-radius: 10px;
    background: #57b0e3;
    color: $brandWhite;
    border: none;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
}

.download_btn {
    padding: 0px 15px;
    margin-right: 10px;
    height: 40px;
    border-radius: 10px;
    background: $PrimaryColor;
    color: $brandWhite;
    border: none;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
}

// styling of bulk modules card4
.card_lime {
    background-color: #eff8e8;
    border: 1px solid #cee5c0 !important;
}

.card_rose {
    background-color: #f8e8e8;
    border: 1px solid #ebc0c0;
}

.card_sky {
    border: 1px solid #cbe3ea;
    background-color: #e8f4f8;
}

.Batch_Details_Card {
    border: 1px solid $formHeaderBorder;
}

@media (max-width: 767px) {
    .Batch_Details_Card {
        flex-direction: column !important;
    }
}

// .p-confirm-popup {
//     position: absolute;
//     top: 9%;
//     left: 82%;
//     margin-top: 10px;
// }

.p-confirm-popup {
    position: absolute;
    top: 40%;
    left: 40%;
    background: #ffffff;
    margin-top: 10px;
    /* right: 50%; */
}

.p-confirm-popup-footer button[aria-label='No'] {
    font-size: 16px;
    height: 40px;
    font-weight: 300;
    background-color: #535965;
    color: #ffffff;
    border: 1px solid #509793;
    border-radius: 5px;
    cursor: pointer;
    width: 50px;
    font-family: 'Poppins', sans-serif;
    padding-right: 5px;
    /* Replace with the desired color for "No" button */
}

.p-confirm-popup-footer button[aria-label='Yes'] {
    font-size: 16px;
    height: 40px;
    font-weight: 300;
    background-color: #509793;
    color: #ffffff;
    border: 1px solid #509793;
    border-radius: 5px;
    cursor: pointer;
    width: 50px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    margin-left: 5px;
    transition: ease-in-out 0.5s;
    /* Replace with the desired color for "Yes" button */
}

@media (max-width: 1024px) {
    .Top__Btn__Pricing {
        display: flex;
        justify-content: center;

        > div:nth-child(1) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0.2rem;
            justify-items: stretch;
        }
    }

    .Top__Btn__Commission {
        display: flex;
        justify-content: center;

        > div:nth-child(1) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0.2rem;
            justify-items: stretch;
        }
    }
}

.bg_dim {
    background-color: #509793;
}

/* For green nodes */
// .p-organizationchart .green-node .p-organizationchart-node-content {
//     border: 1px solid #12A189;
//     background: transparent;
//     color: #FFFFFF;
//     padding: 1.143rem;
// }

// .p-organizationchart .red-node .p-organizationchart-node-content {
//     border: 1px solid red;
//     background: transparent;
//     color: #FFFFFF;
//     padding: 1.143rem;
// }

.p-dialog-header {
    margin-bottom: -25px;
}

.Crp__Model__Fields {
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin: 10px 0px;
}

.Crp__Model__Textarea {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // text-align: center;
    // min-height: 110vh;
    margin: 0;
    padding: 0.375rem 1rem !important;
    line-height: 2;
    // font-size: 20px;
    // font-weight: 500;
    // height: 100%;
}

.Crp__Model__Textarea_Size {
    height: 736px !important;
    font-size: 4rem;
    text-align: center;
    line-height: 736px;
}

.Crp__Model__Geographies__TextArea {
    height: 192px !important;
    font-size: 4rem;
    text-align: center;
    line-height: 192px;
}

.Crp__Model__Transactions__TextArea {
    height: 283px !important;
    font-size: 4rem;
    text-align: center;
    line-height: 283px;
}

.Crp__Model__Heading {
    border-bottom: 1px solid;
    margin: 10px 0px;

    .heading___ {
        font-weight: 700 !important;
    }
}

.rc_table {
    border-collapse: collapse;
}

.rc_table th {
    border-bottom: 1px solid black;
    border: 2px solid whitesmoke;
    padding: 15px;
}

.rc_table td {
    text-align: center;
    border: 2px solid whitesmoke;
    padding: 15px;
}
.blur {
    -webkit-filter: blur(4px);
    filter: blur(4px);
}
.blur_eye i {
    z-index: 1;
}
.disable-arrows .p-node-toggler {
    opacity: 0.5;
    /* Adjust the opacity as needed */
    pointer-events: none;
    /* Make them non-interactive */
}

.save_btn_switch.Usergroup__SearchBtn .Btn__Dark__Search {
    height: 35px !important;
}

.Reports_Tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #fff !important;
}

.Reports_Tab .p-tabview .p-tabview-panels {
    padding: 0 !important;
}

.Reports_Tab .p-tabview.p-component {
    margin-bottom: 1rem;
}

.accountApprovalImage img {
    object-fit: cover;
    width: 100%;
}

.customMultiselect .p-multiselect-token:has(.p-multiselect-token-label:empty) {
    display: none;
}

.Down__Btn_Excel_Loan_Management {
    width: 400px;
    display: flex;
    justify-content: flex-end;
    .Btn__Dark {
        font-size: 16px;

        height: 40px;

        font-weight: 500;

        background-color: $PrimaryColor;

        color: $brandWhite;

        border: 1px solid $PrimaryColor;

        border-radius: 10px;

        cursor: pointer;

        width: 200px;

        font-family: $titleTextFont;

        margin-bottom: 10px;

        span {
            font-weight: 500;
        }

        &:hover,
        :active,
        :focus {
            background-color: $brandWhite;

            color: $PrimaryColor;

            // border: 1px solid $PrimaryColor;
        }
    }
}

.lendingProductCalendar {
    input {
        background-color: transparent;
        border: 0px;
    }
}

.lendingProductCalendarPanel {
    .p-datepicker-header {
        display: none;
    }
}

.menuPermissionList {
    .p-accordion-toggle-icon {
        display: none;
    }
    .toggleIcon {
        color: $PrimaryColor;
    }
    table {
        thead {
            display: none;
        }
    }
}

.Card__Highlighted {
    border: 1px solid $validationLabelColor;
    border-radius: 8px;
    background: $brandWhite;
    padding: 16px;
    box-sizing: border-box;
}

.blurMobile {
    filter: blur(2px);
}

.cmsMobileView__main {
    .cmsMobileView__company {
        .cmsMobileView__icon {
            height: 35px;
            width: 35px;
            object-fit: contain;
            position: absolute;
            top: 34%;
            left: 15%;
            z-index: 1;
        }
        .cmsMobileView__title {
            font-weight: bold;
            font-size: 16px;
            position: absolute;
            top: 34%;
            left: 26%;
            z-index: 1;
        }
    }
    .cmsMobileView__bank {
        .cmsMobileView__icon {
            height: 50px;
            width: 50px;
            object-fit: contain;
            position: absolute;
            top: 27%;
            left: 12%;
            z-index: 1;
        }
        .cmsMobileView__title {
            font-weight: bold;
            font-size: 16px;
            position: absolute;
            top: 28%;
            left: 26%;
            max-width: 200px;
            max-height: 25px;
            overflow: hidden;
            z-index: 1;
        }
    }
    .cmsMobileView__category {
        .cmsMobileView__icon {
            height: 50px;
            width: 50px;
            object-fit: contain;
            position: absolute;
            top: 27%;
            left: 12%;
            z-index: 1;
        }
        .cmsMobileView__title {
            font-weight: bold;
            font-size: 16px;
            position: absolute;
            top: 28%;
            left: 26%;
            z-index: 1;
        }
    }
    .cmsMobileView__banner {
        .cmsMobileView__icon {
            height: 100px;
            width: 100px;
            object-fit: contain;
            position: absolute;
            top: 14%;
            right: 15%;
            z-index: 1;
        }
        .cmsMobileView__title {
            /* font-weight: bold; */
            font-size: 14px;
            position: absolute;
            top: 16%;
            left: 13%;
            z-index: 1;
            max-width: 200px;
            margin-bottom: 0;
            max-height: 60px;
            overflow: hidden;
        }
    }
    .cmsMobileView__inputSplash {
        .cmsMobileView__icon {
            height: 100px;
            width: 100px;
            object-fit: contain;
            position: absolute;
            top: 45%;
            right: 40%;
            z-index: 1;
        }
        .cmsMobileView__title {
            font-weight: bold;
            font-size: 14px;
            position: absolute;
            top: 35%;
            left: 10%;
            right: 0;
            z-index: 1;
            max-width: 330px;
            max-height: 25px;
            margin-bottom: 0;
            overflow: hidden;
            text-align: center;
        }
        .cmsMobileView__description {
            /* font-weight: bold; */
            font-size: 12px;
            position: absolute;
            top: 40%;
            left: 10%;
            z-index: 1;
            max-width: 330px;
            margin-bottom: 0;
            max-height: 45px;
            overflow: hidden;
            right: 0;
            text-align: center;
        }
    }
    .cmsMobileView__informationSplash {
        .cmsMobileView__icon {
            height: 100px;
            width: 100px;
            object-fit: contain;
            position: absolute;
            bottom: 25%;
            right: 40%;
            z-index: 1;
        }
        .cmsMobileView__title {
            font-weight: bold;
            font-size: 14px;
            position: absolute;
            bottom: 20%;
            left: 10%;
            right: 0;
            z-index: 1;
            max-width: 330px;
            max-height: 25px;
            margin-bottom: 0;
            overflow: hidden;
            text-align: center;
        }
        .cmsMobileView__description {
            /* font-weight: bold; */
            font-size: 12px;
            position: absolute;
            bottom: 5%;
            left: 10%;
            z-index: 1;
            max-width: 330px;
            height: 105px;
            margin-bottom: 0;
            overflow: hidden;
            right: 0;
            text-align: center;
        }
    }
    .cmsMobileView__redirectSplash {
        .cmsMobileView__icon {
            height: 300px;
            width: 330px;
            object-fit: contain;
            position: absolute;
            top: 42%;
            left: 10%;
            z-index: 1;
        }
        .cmsMobileView__title {
            font-weight: bold;
            font-size: 14px;
            position: absolute;
            top: 33%;
            left: 10%;
            right: 0;
            z-index: 1;
            width: 310px;
            height: 70px;
            margin-bottom: 0;
            overflow: hidden;
            text-align: center;
        }
    }
    .cmsMobileView__ticker {
        .cmsMobileView__title {
            /* font-weight: bold; */
            font-size: 14px;
            position: absolute;
            top: 17%;
            left: 15%;
            z-index: 1;
            max-width: 300px;
            margin-bottom: 0;
            max-height: 20px;
            overflow: hidden;
        }
    }
    .cmsMobileView__promotion {
        .cmsMobileView__icon {
            height: 100px;
            width: 100px;
            object-fit: contain;
            position: absolute;
            bottom: 22%;
            right: 15%;
            z-index: 1;
        }
        .cmsMobileView__title {
            font-weight: bold;
            font-size: 14px;
            position: absolute;
            bottom: 30%;
            left: 13%;
            z-index: 1;
            max-width: 200px;
            margin-bottom: 0;
            height: 20px;
            overflow: hidden;
        }
        .cmsMobileView__description {
            /* font-weight: bold; */
            font-size: 12px;
            position: absolute;
            bottom: 25%;
            left: 13%;
            z-index: 1;
            max-width: 200px;
            margin-bottom: 0;
            height: 35px;
            overflow: hidden;
        }
        .cmsMobileView__date {
            font-weight: bold;
            color: #f35f44;
            font-size: 12px;
            position: absolute;
            bottom: 22%;
            left: 13%;
            z-index: 1;
            max-width: 200px;
            margin-bottom: 0;
            height: 20px;
            overflow: hidden;
        }
    }
    .cmsMobileView__notifications {
        .cmsMobileView__icon {
            height: 80px;
            width: 80px;
            object-fit: contain;
            position: absolute;
            top: 33%;
            left: 15%;
            z-index: 1;
        }
        .cmsMobileView__title {
            font-weight: bold;
            font-size: 14px;
            position: absolute;
            top: 34%;
            right: 25%;
            z-index: 1;
            width: 240px;
            margin-bottom: 0;
            height: 20px;
            overflow: hidden;
        }
        .cmsMobileView__description {
            /* font-weight: bold; */
            font-size: 12px;
            position: absolute;
            top: 36%;
            right: 25%;
            z-index: 1;
            width: 240px;
            margin-bottom: 0;
            height: 50px;
            overflow: hidden;
        }
    }
    .cmsMobileView__category {
        .cmsMobileView__title {
            font-weight: bold;
            font-size: 12px;
            text-align: center;
            position: absolute;
            top: 28.5%;
            left: 34%;
            z-index: 1;
            width: 60px;
            margin-bottom: 0;
            height: 20px;
            overflow: hidden;
        }
    }
    .cmsMobileView__bundle {
        .cmsMobileView__bundleName {
            font-weight: bold;
            font-size: 14px;
            position: absolute;
            top: 35%;
            left: 15%;
            z-index: 1;
            width: 140px;
            height: 40px;
            overflow: hidden;
        }
        .cmsMobileView__amount {
            font-weight: bold;
            font-size: 14px;
            padding: 0px 5px;
            color: #25c9bf;
            position: absolute;
            top: 36%;
            right: 10%;
            z-index: 1;
            width: 70px;
            height: 20px;
            overflow: hidden;
        }
        .cmsMobileView__tagLabel {
            font-weight: bold;
            font-size: 10px;
            border-radius: 10px;
            position: absolute;
            top: 35%;
            right: 30%;
            z-index: 1;
            max-width: 90px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 7px;
            line-height: 1;
            overflow: hidden;
        }
        .cmsMobileView__discountAmount {
            font-weight: bold;
            font-size: 14px;
            padding: 0px 5px;
            text-decoration: line-through;
            text-decoration-color: #f15151;
            position: absolute;
            top: 38%;
            right: 10%;
            z-index: 1;
            width: 70px;
            height: 20px;
            overflow: hidden;
        }
        .cmsMobileView__bundleDescription {
            font-weight: bold;
            font-size: 12px;
            position: absolute;
            top: 50%;
            left: 14%;
            z-index: 1;
            width: 240px;
            height: 20px;
            overflow: hidden;
            color: #ecb18a;
        }
        .cmsMobileView__numbers {
            p {
                font-weight: bold;
                font-size: 12px;
                position: absolute;
                z-index: 1;
                height: 20px;
                overflow: hidden;
            }
            .cmsMobileView__onnetMinutes {
                top: 41%;
                left: 15%;
                width: 45px;
            }
            .cmsMobileView__offnetMinutes {
                top: 41%;
                left: 37%;
                width: 45px;
            }
            .cmsMobileView__bundleSms {
                top: 41%;
                right: 31%;
                width: 45px;
            }
            .cmsMobileView__internetData {
                top: 41%;
                right: 11%;
                width: 45px;
            }
            .cmsMobileView__validity {
                top: 48%;
                left: 14%;
                width: 240px;
            }
        }
        .cmsMobileView__labels {
            p {
                font-weight: bold;
                font-size: 12px;
                position: absolute;
                z-index: 1;
                height: 20px;
                overflow: hidden;
            }
            .cmsMobileView__onnetMinutesLabel {
                top: 43%;
                left: 15%;
                width: 50px;
            }
            .cmsMobileView__offnetMinutesLabel {
                top: 43%;
                left: 37%;
                width: 50px;
            }
            .cmsMobileView__bundleSmsLabel {
                top: 43%;
                right: 29%;
                width: 50px;
            }
            .cmsMobileView__internetDataLabel {
                top: 43%;
                right: 9%;
                width: 50px;
            }
        }
    }
}

.DmoMerchantQRCode {
    .Down__Btn {
        .Btn__Add {
            width: 250px !important;
        }
    }
}

.colorPickerForInput {
    position: absolute;
    margin-top: 10px;
    z-index: 1;
    .closingIcon {
        position: absolute;
        right: -20px;
        cursor: pointer;
    }
}

.custom-dropdown {
    .p-dropdown-trigger {
        display: none !important;
    }
}
